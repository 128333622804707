/** ****** My Swiss Lunch v0.1 ****** */
/** ****** MSL Juin 2021      ****** */
/** *** Terms.VUE Page Conditions Générales **** */

/** b-collapse avec le contenu  */



<template>
  <div class="terms">
            <div>
              <div class="divider is-centered">
                <h6>My Swiss Lunch - Conditions Générales</h6>
              </div>
            </div>
    <section>  
        <div class="container">
     
     Texte des conditions Générales - A venir à l'ouverture du service !
     </div>
    </section>
  </div>
</template>

<script>
export default {
  name:"Terms"
}
</script>

/** ****** My Swiss Lunch v0.1 ****** */
/** ****** MSL Juin 2021      ****** */
/** *** Privacy.VUE Page Conditions Générales **** */

/** b-collapse avec le contenu  */



<template>
  <div class="privacy">
            <div>
              <div class="divider is-centered">
                <h6>My Swiss Lunch - Cookies et Vie Privée</h6>
              
              </div>
            </div>
    <section>    <div class="container">
     
     Texte Cookies et Vie Privée - A venir !</div>
    </section>
  </div>
</template>

<script>
export default {
  name:"Privacy"
}
</script>
